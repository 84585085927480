import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CookieService } from './cookie.service';
import { Documentacion } from '../models/documentacion.model';
import { Plantilla } from '../models/plantilla.model';
import { Mail } from '../models/envioMail.model';
import { environment } from '../../../environments/environment';

// const baseUrl = 'https://gestionmontepio.gruposca.net/APIGESTION-1/';

const baseUrl = environment.baseURL;


@Injectable({
  providedIn: 'root'
})
export class ApiService {


  constructor(private http: HttpClient,
    public cookieService: CookieService,

  ) { }

  getData(): Observable<any> {
    const url = baseUrl + 'posts';
    return this.http.get<any>(url);
  }

  login(dni: string, password: string): Observable<any> {
    const url = baseUrl + 'public/login';
    const body = { username: dni, password: password };


    return this.http.post<any>(url, body);
  }

  getHome(): Observable<any> {
    const url = baseUrl + 'gestion/homegestion';


    // Realizar la petición HTTP GET con los headers incluidos
    return this.http.get<any>(url);
   }

  getAsociados(): Observable<any> {
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}')
    const url = baseUrl + 'gestion/asociados/min';


    // Realizar la petición HTTP GET con los headers incluidos
    return this.http.get<any>(url);
   }

  getUsuarios(): Observable<any> {
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}')
    const url = baseUrl + 'gestion/usuarios';


    // Realizar la petición HTTP GET con los headers incluidos
    return this.http.get<any>(url);
   }

   getAsociadosMail(): Observable<any> {
    const url = baseUrl + 'gestion/asociados/emails'

    return this.http.get<any>(url);
  }
  getAsociadosTipo(): Observable<any> {
    const url = baseUrl + 'gestion/tipoasociados'

    return this.http.get<any>(url);
  }
  getAsociadosSituacion(): Observable<any> {
    const url = baseUrl + 'gestion/situaciones'

    return this.http.get<any>(url);
  }

   getAccesos(): Observable<any> {
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}')
    const url = baseUrl + 'gestion/accesosasociados';


    // Realizar la petición HTTP GET con los headers incluidos
    return this.http.get<any>(url);
   }

   getAccesosSearch(page: number, filter: string, order: string, direction: string, items: number): Observable<any> {
    const url = baseUrl + 'gestion/accesosasociados/search';
    const body = { page: page, filters: {data: filter}, order: order, direction: direction, items: items};
    console.log(body)

    // Realizar la petición HTTP GET con los headers incluidos
    return this.http.post<any>(url, body);
   }

   getDocumentacion(): Observable<any> {
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}')
    const url = baseUrl + 'gestion/documentos/min';


    // Realizar la petición HTTP GET con los headers incluidos
    return this.http.get<any>(url);
   }

   getPlantillas(): Observable<any> {
    const url = baseUrl + 'gestion/plantillas/min';
    // Realizar la petición HTTP GET con los headers incluidos
    return this.http.get<any>(url);
   }

   getReclamaciones(): Observable<any> {
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}')
    const url = baseUrl + 'gestion/reclamaciones/min';


    // Realizar la petición HTTP GET con los headers incluidos
    return this.http.get<any>(url);
   }

  getDetallesAsociado(id:string): Observable<any> {
    const url = baseUrl + 'gestion/asociado/' + id;

    return this.http.post<any>(url, null);
  }

  getDetallesReclamacion(id:string): Observable<any> {
    const url = baseUrl + 'gestion/reclamaciones/' + id;

    return this.http.post<any>(url, null);
  }

  getDetallesUser(): Observable<any> {
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}')

    const url = baseUrl + 'gestion/usuario/' + user.id;

    return this.http.post<any>(url, null);
  }

  getDetallesDocument(id:number): Observable<any> {

    const url = baseUrl + 'gestion/documentos/' + id;

    return this.http.post<any>(url, null);
  }

  getDetallesPlantilla(id:string): Observable<any> {

    const url = baseUrl + 'gestion/plantillas/' + id;

    return this.http.post<any>(url, null);
  }

  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    const url = baseUrl + 'gestion/changepass';
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}')
    const body = { id: user.id, oldPassword: oldPassword, newPassword: newPassword};

    return this.http.post<any>(url, body);
  }

  newDocument(document: Documentacion): Observable<any> {
    const url = baseUrl + 'gestion/documentos/alta';
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}')
    const body = { id: 0, fecha: document.fecha, referencia: document.referencia , file_name: document.file_name, file_data: document.file_data, asociado: document.asociadoId, visible: document.visible };

    return this.http.post<any>(url, body);
  }

  saveDocument(document: Documentacion): Observable<any> {
    const url = baseUrl + 'gestion/documentos/save';
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}')
    const body = { id: document.id, fecha: document.fecha, referencia: document.referencia , file_name: document.file_name, file_data: document.file_data, asociado: document.asociadoId, visible: document.visible };

    return this.http.post<any>(url, body);
  }

  deleteDocument(id: number): Observable<any> {
    const url = baseUrl + 'gestion/documentos/delete/' + id;

    return this.http.post<any>(url, null);
  }

  newPlantilla(plantilla: Plantilla): Observable<any> {
    const url = baseUrl + 'gestion/plantillas/alta';
    const body = { id: 0, nombre: plantilla.nombre, html: plantilla.html , activo: plantilla.activo };

    return this.http.post<any>(url, body);
  }

  savePlantilla(plantilla: Plantilla): Observable<any> {
    const url = baseUrl + 'gestion/plantillas/save';
    const body = { id: plantilla.id, nombre: plantilla.nombre, html: plantilla.html , activo: plantilla.activo };

    return this.http.post<any>(url, body);
  }

  deletePlantilla(id: number): Observable<any> {
    const url = baseUrl + 'gestion/plantillas/delete/' + id;

    return this.http.post<any>(url, null);
  }

  mailPendientes(): Observable<any> {
    const url = baseUrl + 'gestion/emails/pendientes';
    return this.http.post<any>(url, null);
  }
  envioMail(mails: Mail[]): Observable<any> {
    const url = baseUrl + 'gestion/emails/alta';
    const body =  mails;

    return this.http.post<any>(url, body);
  }

  getAportaciones(id:string): Observable<any> {
    const url = baseUrl + 'gestion/aportaciones/' + id;

    return this.http.post<any>(url, null);
  }

  getPrestaciones(id:string): Observable<any> {
    const url = baseUrl + 'gestion/prestaciones/' + id;

    return this.http.post<any>(url, null);
  }

  getDerechos(id:string): Observable<any> {
    const url = baseUrl + 'gestion/derechos/' + id;

    return this.http.post<any>(url, null);
  }
//        "nombre":"",
//        "apellidos":"",
//        "numero":"",
//        "dni":null,
//        "fnacDesde":"",
//         "fnacHasta":"",
//        "apoDesde":"2025-01-01",
//         “apoHasta":"2025-01-31",
//         "situacion":X,
//          "tipo":Y

  getListadoAportacionesSearch(page: number, nombre: string, apellidos:string, numero: string, dni: string, fnacDesde: string, fnacHasta: string, apoDesde: string, apoHasta: string, situacionAso: number, tipoAso: number, tipoApo: number, order: string, direction: string, items: number): Observable<any> {
    const url = baseUrl + 'gestion/aportaciones/all';
    const body = { page: page, filters: {nombre: nombre, apellidos: apellidos, numero: numero, dni: dni, fnacDesde: fnacDesde, fnacHasta: fnacHasta, apoDesde: apoDesde, apoHasta: apoHasta, situacion: situacionAso, tipo: tipoAso, tipoAportacion: tipoApo }, order: order, direction: direction, items: items};
    console.log(body)

    // Realizar la petición HTTP GET con los headers incluidos
    return this.http.post<any>(url, body);
  }
  getTipoAportaciones(): Observable<any> {
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}')
    const url = baseUrl + 'gestion/tipoaportaciones';

    return this.http.get<any>(url);
  }

  getTipoAportacionById(id: number): Observable<any> {
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}');
    const url = baseUrl + 'gestion/tipoaportacion/' + id;
 
    return this.http.post<any>(url, { id: id });
  }
  saveTipoAportacion(tipoAportacion: any): Observable<any> {
    const url = baseUrl + 'gestion/tipoaportacion/alta';
    return this.http.post<any>(url, tipoAportacion);
  }
  deleteTipoAportacion(id: number): Observable<any> {
    const url = baseUrl + 'gestion/tipoaportacion/delete/' + id;
    return this.http.post<any>(url, { id: id });
  }
  updateTipoAportacion(tipoAportacion: any): Observable<any> {
    const url = baseUrl + 'gestion/tipoaportacion/save';
    if (!tipoAportacion.id) {
      throw new Error('Falta el ID del tipo de aportación');
    }
    return this.http.post<any>(url, tipoAportacion);
  }

  getTipoPrestaciones(): Observable<any> {
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}')
    const url = baseUrl + 'gestion/tipoprestaciones';
    
    return this.http.get<any>(url);
  }

  getTipoPrestacionesById(id:string): Observable<any> {
    const url = baseUrl + 'gestion/tipoprestacion/' + id;

    return this.http.post<any>(url, null);
  }

  updateTipoPrestacion(id: number, data: any): Observable<any> {
    const url = baseUrl + 'gestion/tipoprestacion/save';

    return this.http.post<any>(url, data);
  }

  createTipoPrestacion(data: any): Observable<any> {
    const url = baseUrl + 'gestion/tipoprestacion/alta';

    return this.http.post<any>(url, data);
  }
  
  deleteTipoPrestacion(id: number): Observable<any> {
    const url = baseUrl + 'gestion/tipoprestacion/delete/' + id;

    return this.http.post<any>(url, null);
  }
  
  getTipoBajas(): Observable<any> {
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}')
    const url = baseUrl + 'gestion/bajatipos';
    
    return this.http.get<any>(url);
  }

  getTipoBajasById(id:string): Observable<any> {
    const url = baseUrl + 'gestion/bajatipo/' + id;

    return this.http.post<any>(url, null);
  }

  updateTipoBajas(id: number, data: any): Observable<any> {
    const url = baseUrl + 'gestion/bajatipo/save';

    return this.http.post<any>(url, data);
  }

  createTipoBajas(data: any): Observable<any> {
    const url = baseUrl + 'gestion/bajatipo/alta';

    return this.http.post<any>(url, data);
  }
  
  deleteTipoBajas(id: number): Observable<any> {
    const url = baseUrl + 'gestion/bajatipo/delete/' + id;
 
    return this.http.post<any>(url, { id: id });
  }

  getParentesco(): Observable<any> {
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}')

    const url = baseUrl + 'gestion/parentescos';

    return this.http.get<any>(url);
  }

  getParentescoById(id: number): Observable<any> {
    const url = baseUrl + 'gestion/parentesco/' + id;

    return this.http.post<any>(url, null);
  }

  createParentesco(data: any): Observable<any> {
    const url = baseUrl + 'gestion/parentesco/alta';

    return this.http.post<any>(url, data);
  }

  updateParentesco(id: number, data: any): Observable<any> {
    const url = baseUrl + 'gestion/parentesco/save';

    return this.http.post<any>(url, data);
  }

  deleteParentesco(id: number): Observable<any> {
    const url = baseUrl + 'gestion/parentesco/delete/' + id;

    return this.http.post<any>(url, null);
  }

  getSituacionAsociado(): Observable<any> {
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}')

    const url = baseUrl + 'gestion/situaciones';

    return this.http.get<any>(url);
  }

  getSituacionAsociadoById(id: number): Observable<any> {
    const url = baseUrl + 'gestion/situacion/' + id;

    return this.http.post<any>(url, null);
  }

  createSituacionAsociado(data: any): Observable<any> {
    const url = baseUrl + 'gestion/situacion/alta';

    return this.http.post<any>(url, data);
  }

  updateSituacionAsociado(id: number, data: any): Observable<any> {
    const url = baseUrl + 'gestion/situacion/save';

    return this.http.post<any>(url, data);
  }

  deleteSituacionAsociado(id: number): Observable<any> {
    const url = baseUrl + 'gestion/situacion/delete/' + id;

    return this.http.post<any>(url, null);
  }

  getTipoAsociados(): Observable<any> {
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}')
    const url = baseUrl + 'gestion/tipoasociados';
   
    return this.http.get<any>(url);
  }
 
  getTipoAsociadosById(id:string): Observable<any> {
    const url = baseUrl + 'gestion/tipoasociado/' + id;
 
    return this.http.post<any>(url, null);
  }
 
  updateTipoAsociados(id: number, data: any): Observable<any> {
    const url = baseUrl + 'gestion/tipoasociado/save';
 
    return this.http.post<any>(url, data);
  }
 
  createTipoAsociados(data: any): Observable<any> {
    const url = baseUrl + 'gestion/tipoasociado/alta';
 
    return this.http.post<any>(url, data);
  }
 
  deleteTipoAsociados(id: number): Observable<any> {
    const url = baseUrl + 'gestion/tipoasociado/delete/' + id;
 
    return this.http.post<any>(url, { id: id });
  }
  
  updateBasePrestacion(id: number, data: any): Observable<any> {
    const url = baseUrl + '/gestion/asociado/editbasepresta';

    return this.http.post<any>(url, data);
  }

  updateBajaAsociado(id: number, data: any): Observable<any> {
    const url = baseUrl + '/gestion/asociado/editbaja';

    return this.http.post<any>(url, data);
  }


  getEdadesJubilacion(): Observable<any> {
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}')
    const url = baseUrl + 'gestion/edadesjubilacion';
   
    return this.http.get<any>(url);
  }

  getEdadesJubilacionById(id:number): Observable<any> {
    const url = baseUrl + 'gestion/edadesjubilacion/' + id;
 
    return this.http.post<any>(url, null);
  }
 
  updateEdadesJubilacion(id: number, data: any): Observable<any> {
    const url = baseUrl + 'gestion/edadesjubilacion/save';
 
    return this.http.post<any>(url, data);
  }
 
  createEdadesJubilacion(data: any): Observable<any> {
    const url = baseUrl + 'gestion/edadesjubilacion/alta';
 
    return this.http.post<any>(url, data);
  }
 
  deleteEdadesJubilacion(id: number): Observable<any> {
    const url = baseUrl + 'gestion/edadesjubilacion/delete/' + id;
 
    return this.http.post<any>(url, { id: id });
  }

  getAportacionesByAsociado(data: any): Observable<any> {
    const url = baseUrl + 'gestion/aportaciones/byAsociado';

    return this.http.post<any>(url, data);
  }

  getAportacionesByTipo(data:any): Observable<any> {
    const url = baseUrl + 'gestion/aportaciones/byTipo';
    
    return this.http.post<any>(url, data);
  }

  getPrestacionesByAsociado(data: any): Observable<any> {
    const url = baseUrl + 'gestion/prestaciones/byAsociado';
 
    return this.http.post<any>(url, data);
  }

  getPrestacionesByTipo(data: any): Observable<any> {
    const url = baseUrl + 'gestion/prestaciones/byTipo';
 
    return this.http.post<any>(url, data);
  }

  getFallecimientos(): Observable<any> {
    const url = baseUrl + 'gestion/fallecimientos';
   
    return this.http.get<any>(url);
  }

  deleteFallecimiento(id: number): Observable<any> {
    const url = baseUrl + 'gestion/fallecimientos/delete/' + id;
 
    return this.http.post<any>(url, { id: id });
  }

  getDetallesFallecimiento(id:number): Observable<any> {
    const url = baseUrl + 'gestion/fallecimientos/' + id;
 
    return this.http.post<any>(url, null);
  }

  updateFallecimiento(data: any): Observable<any> {
    const url = baseUrl + 'gestion/fallecimientos/save';

    return this.http.post<any>(url, data);
  }

  createFallecimiento(data: any): Observable<any> {
    const url = baseUrl + 'gestion/fallecimientos/alta';
 
    return this.http.post<any>(url, data);
  }
}